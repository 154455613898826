import { BrickSetup } from 'components/bricks/types/brick.type';

const setup: BrickSetup = {
    subType: 'cm360_ad',
    title: 'Google CM - Ad',
    channel: 'display',
    outputAction: 'publish',
    platform: 'cm360',
    description: 'Create an ad in campaign manager.',
    defaultTab: 'settings',
    addBrickText: 'Add an ad',
    availableParentBricks: ['cm360_placement'],
    availableChildBricks: [],
    additionalVars: ['feed_custom', 'cm360_campaign'],
    tabs: [
        {
            key: 'settings',
            title: 'Settings',
            type: 'multiInput',
            component: 'settings'
        },
        {
            key: 'briefing',
            title: 'Briefing',
            type: 'component',
            component: 'briefing'
        },
        {
            key: 'publish',
            title: 'Publish',
            type: 'component',
            component: 'output'
        }
    ]
};

export default setup;
