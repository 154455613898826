import React from 'react';
import { CreativeV2 } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import useBrick from 'components/bricks/hooks/useBrick';
import BricksCreativeOverview from 'components/bricks/components/shared/components/creative-overview-wrapper';

import './styles/creatives.scss';

const Creatives = () => {
    const { brick, brickChildren } = useBrick();

    if (!brick) return null;

    const getCreatives = () => {
        const creatives: CreativeV2[] = [];

        brickChildren.forEach((childBrick) => {
            const assetData = childBrick.data?.creatives;
            if (assetData && assetData.length > 0) {
                creatives.push(assetData[0]);
            }
        });

        return creatives;
    };

    return (
        <div className="bricks-asset-set-creatives">
            <BricksCreativeOverview creatives={getCreatives()} availableViews={['canvasFixed', 'canvasFree', 'single']} brick={brick} />
        </div>
    );
};

export default Creatives;
