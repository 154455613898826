import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import { Brick, BrickSetup } from 'components/bricks/types/brick.type';
import Translation from 'components/data/Translation';

const setup: BrickSetup = {
    subType: 'asset_set',
    title: Translation.get('mediaSet', 'bricks'),
    channel: 'assets',
    description: Translation.get('addBrickDialog.mediaSet.description', 'bricks'),
    addBrickText: Translation.get('addMediaSet', 'bricks'),
    availableParentBricks: [],
    availableChildBricks: ['single_asset'],
    outputAction: 'download',
    defaultTab: 'upload',
    dynamicTab: (brick: Brick) => {
        const children = BrickHelpers.getChildrenOfBrick(brick);
        if (!children) return;

        const hasChildWithCreative = children.some((child) => child.subType === 'single_asset' && child.data?.creatives?.length);

        if (hasChildWithCreative) return 'creatives';

        return;
    },
    tabs: [
        {
            key: 'settings',
            title: 'Settings',
            type: 'multiInput'
        },
        {
            key: 'upload',
            title: 'Upload',
            type: 'component',
            component: 'upload'
        },
        {
            key: 'creatives',
            title: 'Creatives',
            type: 'component',
            component: 'creatives'
        },
        {
            key: 'briefing',
            title: 'Briefing',
            type: 'component',
            component: 'briefing'
        },
        {
            key: 'publish',
            title: 'Download',
            type: 'component',
            component: 'output',
            rights: ['brickManagement', 'brickPublish']
        }
    ],
    config: {
        general: {
            publish: {
                previewType: 'creatives'
            }
        }
    }
};

export default setup;
