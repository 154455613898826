import { BrickSetup } from 'components/bricks/types/brick.type';

const setup: BrickSetup = {
    subType: 'cm360_placementGroup',
    title: 'Google CM - Placement group',
    channel: 'display',
    platform: 'cm360',
    outputAction: 'publish',
    description: 'Create a placement group in campaign manager.',
    defaultTab: 'settings',
    addBrickText: 'Add placement',
    availableParentBricks: ['cm360_campaign'],
    availableChildBricks: ['cm360_placement'],
    additionalVars: ['feed_custom', 'cm360_campaign', 'cm360_placement', 'cm360_placementGroup'],
    tabs: [
        {
            key: 'settings',
            title: 'Settings',
            type: 'multiInput',
            component: 'settings'
        },
        {
            key: 'briefing',
            title: 'Briefing',
            type: 'component',
            component: 'briefing'
        },
        {
            key: 'publish',
            title: 'Publish',
            type: 'component',
            component: 'publish'
        }
    ]
};

export default setup;
