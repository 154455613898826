import { BrickSetup } from 'components/bricks/types/brick.type';
import Translation from 'components/data/Translation';

const setup: BrickSetup = {
    subType: 'single_asset',
    title: Translation.get('singleMediaFile', 'bricks'),
    channel: 'assets',
    description: Translation.get('addBrickDialog.singleMediaFile.description', 'bricks'),
    addBrickText: 'Add asset',
    availableParentBricks: ['asset_set'],
    availableChildBricks: [],

    outputAction: 'download',
    defaultTab: 'creatives',
    tabs: [
        {
            key: 'settings',
            title: 'Settings',
            type: 'multiInput'
        },
        {
            key: 'creatives',
            title: Translation.get('labels.upload', 'common'),
            type: 'component',
            component: 'creative'
        },
        {
            key: 'briefing',
            title: 'Briefing',
            type: 'component',
            component: 'briefing'
        },
        {
            key: 'publish',
            title: 'Download',
            type: 'component',
            component: 'output',
            rights: ['brickManagement', 'brickPublish']
        }
    ],
    config: {
        general: {
            publish: {
                previewType: 'creatives'
            }
        }
    }
};

export default setup;
